import {
  IInitAppForPage,
  IPlatformAPI,
  IPlatformServices,
  IWixAPI,
  IAppData,
} from '@wix/native-components-infra/dist/es/src/types/types';
import { initRaven } from '@wix/bookings-adapters-reporting/dist/src/sentry/sentry-adapter';
import {
  BOOKINGS_WIDGET_ID,
  BOOKINGS_OFFERING_LIST_WIDGET_ID,
  BOOKINGS_LIST_PAGE_ID,
} from '@wix/bookings-uou-domain';
import { createWidgetController } from '@wix/bookings-widget-viewer/dist/src/platform/create-widget-controller';
import {
  createOfferingListWidgetController,
  createMainPageController,
} from '@wix/bookings-widget/src/index';
import {
  BookingsErrorReporter,
  withBookingsErrorReporter,
} from '@wix/bookings-adapters-reporting/dist/src/error-reporter/error-adapter';
import {
  dailyTimeTableCreateController,
  DAILY_TIMETABLE_WIDGET_ID,
} from '@wix/bookings-app-builder-controllers';

const controllerByType = {
  [BOOKINGS_WIDGET_ID]: createWidgetController,
  [BOOKINGS_OFFERING_LIST_WIDGET_ID]: createOfferingListWidgetController,
  [BOOKINGS_LIST_PAGE_ID]: createMainPageController,
  [DAILY_TIMETABLE_WIDGET_ID]: dailyTimeTableCreateController,
};

let ravenReporter: BookingsErrorReporter = () => null;

export function createControllers(controllerConfigs) {
  return controllerConfigs.map(async config =>
    controllerByType[config.type]
      ? withBookingsErrorReporter(ravenReporter)(
          await (controllerByType[config.type] as any)(config, ravenReporter),
        )
      : undefined,
  );
}

export const initAppForPage: IInitAppForPage = async (
  initParams: IAppData,
  apis: IPlatformAPI,
  namespaces: IWixAPI,
  platformServices: IPlatformServices,
) => {
  ravenReporter = initRaven(platformServices, initParams);
};
